import React from "react"
import {
  createMuiTheme,
  colors,
  MuiThemeProvider,
  CssBaseline,
  responsiveFontSizes,
} from "@material-ui/core"

let muiTheme = createMuiTheme({
  palette: {
    // type: props.themeType,

    background: {
      footer: colors.grey[300],
      message: colors.lightBlue[50],
    },
    primary: {
      light: "#C62828",
      main: "#C62828",
      dark: "#C62828",
    },
    secondary: { main: colors.blue[700] },
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid ${colors.red[500]}`,
        },
        "&&&&:hover:before": {
          borderBottom: `2px solid ${colors.red[500]}`,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 21,
        fontWeight: 700,
      },
      h2: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 46,
        fontWeight: 700,
      },
      h4: {
        fontFamily: "'Lato', sans-serif",
        fontSize: 36,
        fontWeight: 700,
      },
      body1: {
        lineHeight: "30px",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
})

muiTheme = responsiveFontSizes(muiTheme, {
  factor: 3,
  breakpoints: ['xs', 'sm', 'md', 'lg'],
})

export default ({ element }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      {element}
    </MuiThemeProvider>
  )
}
