// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-js": () => import("../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-estimate-index-js": () => import("../src/pages/estimate/index.js" /* webpackChunkName: "component---src-pages-estimate-index-js" */),
  "component---src-pages-estimate-success-js": () => import("../src/pages/estimate/success.js" /* webpackChunkName: "component---src-pages-estimate-success-js" */),
  "component---src-pages-how-it-works-js": () => import("../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

